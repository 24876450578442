<template>
  <div v-if="!adminPermission">
    <router-view :name="user.role"></router-view>

    <div class="v-main__wrap v-content__wrap logpage">
      <div>
        <div>
          <header class="dash-app-bar v-sheet theme--dark v-toolbar v-toolbar--flat" style="height: 100px;">
            <div class="v-toolbar__content" style="height: 100px;"><button type="button"
                class="v-app-bar__nav-icon v-btn v-btn--icon v-btn--round theme--dark v-size--default"><span
                  class="v-btn__content"><i aria-hidden="true"
                    class="v-icon notranslate mdi mdi-menu theme--dark"></i></span></button>
              <div class="v-toolbar__title dash-headline">Logs</div>
              <div class="spacer"></div>
              <div class="spacer"></div><span class="v-tooltip v-tooltip--bottom"><!----></span><span aria-haspopup="true"
                aria-expanded="false">Version: 0.1.6</span>
            </div>
          </header>


          <v-dialog v-model="viewAdditionalDataDialog" max-width="900px" persistent>
            <v-card>
              <div class="PopHead">
                <v-card-title id="card-title">
                  Additional Information
                </v-card-title>
                <v-btn icon @click="viewAdditionalDataDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="PopBody">
                <div v-if="parsedObject">
                  <v-card-text v-if="!dataAddi">  
                    <div>
                      <p>Url : {{ this.url }}</p>
                    </div>
                    <div>
                      <p>Payload :-</p>
                    </div>
                    <div v-for="[key, value] of Object.entries(this.parsedObject)" :key="key">
                      <p>{{ key }} : {{ value }}</p>
                    </div>
                  </v-card-text>

                  <v-card-text v-if="typeof dataAddi === 'object'">
                    <div>
                      <p>Url : {{ this.url }}</p>
                    </div>
                    <div>
                      <p>Payload :-</p>
                    </div>
                    <div v-for="[key, value] of Object.entries(this.dataAddi)" :key="key">
                      <p>{{ key }} : {{ value }}</p>
                    </div>
                  </v-card-text>
                  <div v-else-if="dataAddi && url">
                    <div>{{ this.url }}</div>
                    <div>ID : {{ this.dataAddi }}</div>
                  </div>
                  <div v-else-if="url && parsedObject">
                    <div>{{ this.url }}</div>
                  </div>
                  <!-- <div v-else-if="additionalData === null">
                    <div>No Additional Information Available.</div>
                  </div> -->
                </div>
                <!-- <div v-if=></div> -->
                <!-- <div v-if="Object.keys(parsedObject).length===0">dkjkrjglkrk -->

                <div v-else>
                  <p>No Additional Information Available.</p>
                </div>
              </div>
            </v-card>
          </v-dialog>

          <div class="container container--fluid grid-list-xl px-5 pt-2">
            <div role="dialog" class="v-dialog__container"></div>
            <v-row class="align-center">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="start"
            type="date"
            label="Start Date (required)"
            outlined
            :rules="[required, startDateValidation]"
            :error-messages="startErrorMessages"
          ></v-text-field>
          <p v-if="startError">
            <span>{{startDateValidation(start)}}</span>
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="end"
            type="date"
            label="End Date (required)"
            outlined
            :rules="[required, endDateValidation]"
            :error-messages="endErrorMessages"
          ></v-text-field>
          <p v-if="endError">
            <span>{{endDateValidation(end)}}</span>
          </p>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn style="width: 100%;" @click="validateAndApplyFilter" color="primary">Apply Filter</v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn style="width: 100%;" @click="exportDataToCsv" color="blue-grey darken-1" :disabled="this.user.role === 'User'">
                  Export Logs
                </v-btn>
        </v-col>
      </v-row>
            <div class="v-card v-sheet theme--dark" style="max-width: 100%;">
              <!-- <div class="v-card__title mb-6">
                <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
                  clearable></v-text-field>

                <v-select v-model="eventName" :items="p_data" item-text="name" @change="filterElectionList" return-object
                  label="Event Type" class="ml-2" outlined></v-select>

                <v-select v-model="eventType" :items="p_data" item-text="name" @change="filterElectionList" return-object
                  label="Event Date" class="ml-2" outlined></v-select>
                
              </div> -->
              <v-container grid-list-xl fluid>
                <v-layout row wrap class="position-relative">
                  <v-data-table :headers="headers" :items="logsData" :search="search" class="elevation-1"
                    :loading="districtLoading" loading-text="Districts Data Loading ..." style="width: 100%">
                    <template v-slot:[`items.EventName`]="{ items }">
                      {{ items.EventName }}
                    </template>
                    <template v-slot:[`items.EventType`]="{ items }">
                      {{ items.EventType }}
                    </template>
                    <template v-slot:[`items.EventDescription`]="{ items }">
                      {{ items.EventDescription }}
                    </template>
                    <template v-slot:[`items.EventDate`]="{ items }">
                      {{ items.EventDate.split(' ')[0] }}
                    </template>
                    <template v-slot:[`items.UserID`]="{ items }">
                      {{ items.UserID === null ? 'NA' : items.UserID }}
                    </template>
                    <template v-slot:[`item.view`]="{ item }">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, ttprops }">
                          <v-btn icon @click.prevent="viewAdditional(item)" v-bind="ttprops" v-on="on">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View Additional Data</span>
                      </v-tooltip>
                    </template>


                  </v-data-table>
                  <div class="override-btn">

                    <span>Page: {{ pageNumber }}</span>
                    <button @click="previousPage" :disabled="pageNumber === 1"> <i aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chevron-left theme--dark"></i></button>
                    <button @click="nextPage" :disabled="pageNumber === totalPage"><i aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chevron-right theme--dark"></i></button>
                  </div>

                </v-layout>
              </v-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <dash-layout>
      <v-container class="session-container fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8">
            <v-col cols="12" class="d-flex justify-center">
              <h3>You don’t have permission. Please contact Admin.</h3>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </dash-layout>

  </div>
</template>
  
<script>
import api from 'Api';
import { mapGetters } from 'vuex'



export default {
  name: "LogPage",

  computed: {
    ...mapGetters(['user', 'getRouteInfo']),
    required() {
      return (value) => !!value || 'Field is required';
    },
  },

  data() {
    return {
      startErrorMessages: [],
      endErrorMessages: [],
      pageNumber: 1,
      itemPerPage: 10,
      totalPage: '',
      adminPermission: false,
      logsData: [],
      exportMethodData: [],
      viewAdditionalDataDialog: false,
      search: '',
      eventName: null,
      eventType: null,
      additionalData: null,
      parsedObject: null,
      dataAddi: {},
      id: '',
      url: '',
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: 'EventName', align: 'start', sortable: true, value: 'EventName' },
        { text: 'EventType', value: 'EventType' },
        { text: 'EventDescription', value: 'EventDescription' },
        { text: 'EventDate', value: 'EventDate' },
        { text: 'UserName', value: 'UserID' },
        { text: 'View', value: 'view' },
      ],
      start:null,
      end:null,
      startError: false,
      endError: false,
    };
  },

  methods: {
    startDateValidation(value) {
      if (!value) return 'Start date is required';
      const startDate = new Date(value);
      const endDate = this.end ? new Date(this.end) : null;

      if (endDate && startDate.getTime() > endDate.getTime()) {
        return 'Start date must be before end date';
      }

      // Additional validation logic can be added here

      return true;
    },
    endDateValidation(value) {
      if (!value) return 'End date is required';
      const endDate = new Date(value);
      const startDate = this.start ? new Date(this.start) : null;

      if (startDate && endDate.getTime() < startDate.getTime()) {
        return 'End date must be after start date';
      }

      // Additional validation logic can be added here

      return true;
    },
    validateAndApplyFilter() {
      this.startErrorMessages = [this.startDateValidation(this.start)].filter((item) => item != true && item != false);
      this.endErrorMessages = [this.endDateValidation(this.end)].filter((item) => item != true && item != false);
      console.log(this.startErrorMessages.length);
      this.startError = this.startErrorMessages.length ? true : false;
      this.endError = this.endErrorMessages.length ? true : false;
      console.log(this.startErrorMessages, this.endErrorMessages)
      if (!this.startErrorMessages.length && !this.endErrorMessages.length) {
        // Execute filtering logic here
        // Only proceed if both start and end dates are valid
        console.log('Filtering...');
      } else {
        // Display error message or handle invalid dates
        console.log('Invalid dates. Please correct them.');
      }
    },
    async getData() {
      try {
        const response = await api().get(`/log/1?page=${this.pageNumber}&limit=${this.itemPerPage}`);
        console.log('response', response?.data?.logs)
        this.totalPage = response?.data?.total_pages;
        this.logsData = response?.data?.logs?.map((item) => (
          {
            ...item,
            UserID: item.UserID || 'N/A',
          }
        ));
      } catch (error) {
        console.log(error);
      }
    },
    async exportData() {   // call export data API here
      try {
        const response = await api().get(`/log/1?all_records=true`);
        this.exportMethodData = response?.data?.logs
      } catch (error) {
        console.log(error);
      }
    },
    viewAdditional(item) {
      this.viewAdditionalDataDialog = true
      this.parsedObject = null;
      this.additionalData = item.AdditionalData
      const jsonString = item.AdditionalData.replace(/'/g, '"').replace(/None/g, 'null').replace(/True/g, 'true').replace(/False/g, 'false');

      this.id = JSON.parse(jsonString).id;
      this.parsedObject = JSON.parse(jsonString)
      this.url = JSON.parse(jsonString).url;
      this.dataAddi = JSON.parse(jsonString).data;
    },
    closeDialog() {
      this.viewAdditionalDataDialog = false
    },
    exportDataToCsv() {
      console.log('date', this.date)
      const csvContent = this.convertToCsv(this.exportMethodData)
      const blob = new Blob([csvContent], { type: 'test/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export_data(${this.date}).csv`);
      link.click();
    },
    convertToCsv(data) {
      const headers = Object.keys(data[0]);
      const rows = data.map((obj) => headers.map(header => JSON.stringify(obj[header])));
      const headerRow = headers.join(',');
      const csvRows = [headerRow, ...rows.map(row => row.join(','))];
      return csvRows.join('\n');
    },
    async previousPage() {
      if (this.pageNumber > 1) {
        this.pageNumber--;
        await this.getData();
      }
    },
    async nextPage() {
      this.pageNumber++;
      await this.getData();
    },
    startDateValidation(value) {
      if (!value) return 'Start date is required';
      const startDate = new Date(value);
      const endDate = this.end ? new Date(this.end) : null;

      if (endDate && startDate.getTime() > endDate.getTime()) {
        return 'Start date must be before end date';
      }

      // Additional validation logic can be added here

      return true;
    },
    endDateValidation(value) {
      if (!value) return 'End date is required';
      const endDate = new Date(value);
      const startDate = this.start ? new Date(this.start) : null;

      if (startDate && endDate.getTime() < startDate.getTime()) {
        return 'End date must be after start date';
      }

      // Additional validation logic can be added here

      return true;
    },
    applyFilter() {
      const startValid = this.startDateValidation(this.start);
      const endValid = this.endDateValidation(this.end);

      if (startValid === true && endValid === true) {
        // Execute filtering logic here
        // Only proceed if both start and end dates are valid
        // Implement your filtering functionality using start and end dates
        console.log('Filtering...');
      } else {
        // Display error message or handle invalid dates
        console.log('Invalid dates. Please correct them.');
      }
    },
  },

  mounted() {
    if (this.getRouteInfo.to?.name == "AuditJobsList" && this.user?.subscriptionPlan == 0 && this.user.role.toLowerCase() == "user" || this.user.role.toLowerCase() == "observer" || this.user.role.toLowerCase() == "client" || this.user.role.toLowerCase() == "sponsor") {
      this.adminPermission = true;
    }
  },
  created() {
    this.getData();
    this.exportData();
  },

}
</script>